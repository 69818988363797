import logo from './logo.svg';
import './App.css';
import UpAndDown from './UpAndDown.js'


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <UpAndDown/>
      </header>
    </div>
  );
}

export default App;
