import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import './UpAndDown.css';
import FormControl from '@material-ui/core/FormControl';
import {CopyToClipboard} from 'react-copy-to-clipboard';


class UpAndDown extends Component {

     state = {
          string: "I can't take you serious."
     }


    componentDidMount() {
    document.title = "I can't take you serious"
    }

   upAndDown(str){
    if(typeof str === "undefined"){
    return("")}
      console.log(str);
      var newString = str.charAt(0)
      for(var i = 1; i < str.length; i++)
         {if(newString.charAt(i-1)===newString.charAt(i-1).toUpperCase()){
            newString += str.charAt(i).toLowerCase();
         }else{
            newString += str.charAt(i).toUpperCase();
         }
       }
      return newString
    }


    render() {
        return (
            <>
            <div>
		<TextField style = {{display:"flex", width:"90%"}} label={"I can't take you serious."} onChange={event => {
          const { value } = event.target;
          this.setState({ string: value });
        }}/>
                <br/><br/><br/>
          <p>{this.upAndDown(this.state.string)}</p>
          <br/>
          <CopyToClipboard text={this.upAndDown(this.state.string)}>
          <button>Copy to clipboard</button>
        </CopyToClipboard>
          </div>
            </>
        )
    }
}

export default UpAndDown;



